import * as React from "react"

import Header from "../components/header"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Zubehoer = () => (
  <Layout>
    <Seo title="Zubehör" />
    <Header />
    <div
      style={{
        marginTop: `var(--space-5)`,
      }}
    >
      <h2 style={{fontWeight:`bold`}}>
        Zubehör
      </h2>
      
    </div>
  </Layout>
)

export default Zubehoer
